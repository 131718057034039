// eslint-disable-next-line
export const permission = {
  dashboard: ['dashboard.view','dashboard.create','dashboard.update', 'dashboard.delete'],
  analytics: ['analytics.view','analytics.create','analytics.update', 'analytics.delete'],
  performance: ['performance.view','performance.create','performance.update', 'performance.delete'],
  orders: ['orders.view','orders.create','orders.update', 'orders.delete'],
  messages: ['messages.view','messages.create','messages.update', 'messages.delete'],
  rules: ['rules.view','rules.create','rules.update', 'rules.delete'],
  clientProfiles: ['client.view.admin','client.create','client.update', 'client.delete'],
  users:['users.view','users.create','users.update', 'users.delete'],
  settings: ['settings.view','settings.create','settings.update', 'settings.delete'],
  notifications: ['notifications.view','notifications.create','notifications.update', 'notifications.delete'],
  computerVision: ['cv.view','cv.create','cv.update', 'cv.delete'],
  ocr: ['ocr.view','ocr.create','ocr.update', 'ocr.delete'],
  roleManagement: ['rm.view','rm.create','rm.update', 'rm.delete']
};

export const ActionPermissions = {
  ARCHIVE: 'order.action.archive',
  PREVIEW: 'order.review.preview',
  ORDER_REVIEW: 'orders.view',
  REQUEST_FOR_REVISION: 'order.review.request_for_revision',
  MARK_AS_COMPLETE: 'order.review.mark_as_complete',
  UPLOAD_A_DOCUMENT: 'order.review.upload_a_document',
  REVIEWER_ASSIGNMENT: 'order.reviewer_assignment',
  ESCALATE_TO: 'order.escalate_to',
  ASSIGN_MYSELF: 'order.review.assign_myself',
  ARCHIVE_ORDER: 'order.review.archive_order',
  SHARE: 'order.review.action.share',
  DOWNLOAD: 'order.review.download',
  STIPULATION: 'order.review.stipulation',
  DOWNLOAD_REVIEW: 'order.review.download',
  DOWNLOAD_REVIEW_FILES: 'order.review.download_review_files',
  MESSAGES_REVIEW: 'order.review.messages_review',
  CREATE_ORDER: 'orders.create',
  ORDER_STATUS: 'order_status.view',
  REVIEWER: 'reviewer.view',
  DELETE_MESSAGE: 'messages.delete',
  ORDER_REVIEW_CREATE: "order.review.create",
  ORDER_REVIEW_UPDATE: "order.review.update",
  ORDER_REVIEW_DOCUMENT_VIEW: "order.review.document_view",
  REVIEW_FINDING_STATUS_VIEW: "review.finding.status.view",
  REVIEW_FINDING_CREATE: "review.finding.create",
  REVIEW_FINDING_UPDATE: "review.finding.update",
  REVIEW_FINDING_VIEW: "review.finding.view",
  REVIEW_FINDING_DELETE: "review.finding.delete",
  MY_ORDER_VIEW: "my.order.view",
  VIEW_CLIENTS: "client.view.admin",
  GET_NEXT_REVIEW: 'order.get_next_review',
  NAN_RULE_ASSOCIATE: 'nan.rules.associate',
  NAN_RULE_DISASSOCIATE: 'nan.rules.dissociate',
  ACI_RULE_MAPPING: 'aci.rules.mapping',
  GAAR_RULE_MAPPING: 'gaar.rules.mapping',
  EAD_RULE_MAPPING: 'ead.rules.mapping',
  UCDP_RULE_MAPPING: 'ucdp.rules.mapping',
  ADMIN_DASHBOARD: 'dashboard.admin.view',
  REVIEWER_DASHBOARD: 'dashboard.reviewer.view',
  MANAGER_DASHBOARD: 'dashboard.manager.view',
  MANAGE_ORDERS_OOO: 'order.admin_ooo',
  MESSAGES_VIEW: 'messages.view'
}
