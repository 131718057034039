import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UserConfigurationService } from 'src/app/core/services/user-configuration.service';
import { ActionPermissions } from 'src/app/shared/constants';
import { OrderService } from '../../order.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FindingStatues, OrderStatuses } from 'src/app/shared/enums/orders';
import { message } from 'src/app/shared/constants/alerts_messages';
import { ReviewFinding } from '../../orders.interface';
import { FindingTypes } from 'src/app/shared/enums/findingsTypes';
import { ConfirmationModalComponent } from 'src/app/shared/components/confirmation-modal/confirmation-modal.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SelectionModel } from '@angular/cdk/collections';
import { SharedService } from 'src/app/shared/shared.service';
import { FINDINGS_SOURCE, FINDINGS_STATUSES } from 'src/app/shared/constants/orders.const';
import {moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-order-findings-view',
  templateUrl: './order-findings-view.component.html',
  styleUrls: ['./order-findings-view.component.scss']
})
export class OrderFindingsViewComponent implements OnInit, OnChanges {

  @Input() findingsData: ReviewFinding[] = [];
  @Input() originalFindingsData: ReviewFinding[];
  @Input() resolvedFindings = 0;
  @Input() totalFindings: number;
  @Input() rejectedfindings = 0;
  @Input() reviewId: string;
  @Input() orderStatus: string;
  @Input() toggleState: boolean;
  @Input() isPreview: boolean;
  @Input() xpathToScroll: string;
  @Input() findingsTabSelection: string;
  @Input() isActionPerformed: boolean;
  @Input() enableFindingClick: boolean;
  @Input() revisionRequested = false;
  @Input() vendorAndReviewer: any;
  @Input() sortRevisionArray: any[] = [];

  @Output() findingResolved = new EventEmitter<boolean>();
  @Output() updateFindingsList: EventEmitter<{ reloadDoc: boolean, isActionPerformed: boolean }> = new EventEmitter<{ reloadDoc: boolean, isActionPerformed: boolean }>();
  @Output() optionSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() applyFilterOnFindings = new EventEmitter<ReviewFinding[]>();
  @Output() backToReport = new EventEmitter<boolean>();
  @Output() draggedFinding = new EventEmitter<any>();
  @Output() updateSortedList = new EventEmitter<any>();

  findingTypes = FindingTypes as Record<string, string>;
  permission = ActionPermissions;
  message = message;
  findingsFormArray: FormGroup[] = [];
  editingStatus: boolean[] = [];
  showSummary = false;
  selectedOption = [];
  searchTerm = '';
  filteredFindings: ReviewFinding[] = this.findingsData;
  orderId: string;
  token: string;
  findingSortDirection = 'asc';
  countData: { [key: string]: number };
  currentFocus: string | null;
  OrderStatuses = OrderStatuses;
  toggleSearch = false;
  selection = new SelectionModel<any>(true, []);
  selectedStatus: any;
  lastIndex = 0;
  toggleManualFinding = false;
  isBulkStatusChanges = false;
  addBulkActionComment = new FormControl('', [Validators.required]);
  addBulkActionStatus = '';
  isBulkActionCommentRequired = false;
  containerElement: HTMLElement;
  hoveredFindingIndex: number | null = null;
  selectedCheckedIds: string[] = [];
  isAnyCheckboxSelected = false;
  nameOfFindingsSource: string[] = FINDINGS_SOURCE;
  namesOfFindingsStatuses: string[] = FINDINGS_STATUSES;
  selectedStatuses: string[] = [];
  selectedTypes: string[] = [];
  filterFindings: ReviewFinding[]; // To store findings fetched after applying filters
  isFilterExpanded = false;
  isFilterApplied = false;
  findingStatus = FindingStatues;
  previousFilterStatus: string[] = [];
  previousFilterTypes : string[] = [];
  isDragged = false;

  constructor(
    private dialog: MatDialog,
    public userConfig: UserConfigurationService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    public orderService: OrderService,
    private route: ActivatedRoute,
    public sharedService: SharedService,
    public elementRef: ElementRef) {
    this.editingStatus = this.findingsData.map(() => false);
  }

  ngOnInit(): void {
    this.initializeFormArray();
    this.route.params.subscribe(params => {
      this.orderId = params['orderId'];
      this.token = params['token'];
    });
  }

  ngAfterViewInit () {
    this.containerElement = document.getElementById('findingContainer') as HTMLElement;
  }

  focusFinding(finding: ReviewFinding | null) {
    if (this.enableFindingClick && !this.revisionRequested) {
      this.orderService.sendFocus(finding)
      if (finding) {
        this.currentFocus = finding.xpath;
      }
    }
  }

  initializeFormArray(): void {
    this.countData = {
      Approved: 0,
      Rejected: 0,
      'Not Applicable': 0,
    };
    this.filteredFindings?.forEach((finding) => {
      const findingFormGroup = this.formBuilder.group({
        comment: new FormControl(finding.comment, finding?.review_finding_status?.name !== 'Approved' ? [Validators.required] : [])
      });
      findingFormGroup.get('comment')?.setValidators([Validators.required]);
      this.findingsFormArray.push(findingFormGroup);
    });

    this.originalFindingsData?.forEach((finding) => {
      if (finding?.review_finding_status) {
        this.countData[finding.review_finding_status.name]++;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('findingsData' in changes) {
        this.handleFindingsDataChange();
    }
    if (this.xpathToScroll) {
        this.scrollToXpath();
    }
  }

  handleFindingsDataChange() {
    if (this.revisionRequested) {
        this.handleRevisionRequested();
    }
    this.clearCheckboxSelections();
    this.cancelBulkAction();
    this.findingsFormArray = [];
    this.filteredFindings = this.findingsData;
    this.initializeFormArray();
    this.searchTerm = '';
    this.toggleSearch = false;
    this.editingStatus = this.findingsData?.map(() => false);
    this.updateActionStatuses();
    if (this.isFilterApplied && this.isActionPerformed) {
        this.applyFilter();
    }
  }

  handleRevisionRequested() {
    this.selectedStatus = FindingStatues.REJECTED;
    this.findingsTabSelection = FindingStatues.RESOLVED;
    if (this.sortRevisionArray?.length > 0) {
      this.updateSortedRevisionArray();
      this.findingsData = this.sortRevisionArray.filter(finding => !!finding?.comment && finding?.review_finding_status?.name === 'Rejected');
    } else {
      this.findingsData = this.originalFindingsData.filter(finding => !!finding?.comment && finding?.review_finding_status?.name === 'Rejected');
    }
  }

  updateSortedRevisionArray() {
    this.sortRevisionArray = this.sortRevisionArray.map(sorted => {
        const matchingFinding = this.originalFindingsData.find(finding => finding.id === sorted.id);
        if (matchingFinding) {
          sorted.comment = matchingFinding.comment;
        }
        return sorted;
    });
  }

  updateActionStatuses() {
    if (this.filteredFindings?.length > 0) {
      this.filteredFindings.forEach(finding => {
        finding.actionStatus = [
          finding?.review_finding_status?.name === FindingStatues.APPROVED || false,
          finding?.review_finding_status?.name === FindingStatues.REJECTED || false,
          finding?.review_finding_status?.name === FindingStatues.NOT_APPLICABLE || false,
          true
          ];
      });
    }
  }

  scrollToXpath() {
    const inputElement = this.elementRef.nativeElement.querySelector(`[finding-xpath="${this.xpathToScroll}"]`);
    if (inputElement) {
      inputElement.classList.add("selected");
      setTimeout(() => {
        inputElement.classList.remove("selected");
      }, 1000);
      this.containerElement = document.getElementById('findingContainer') as HTMLElement;
      this.orderService.scrollToFinding(inputElement, this.containerElement);
    }
  }

  searchFindings(isValue?: boolean): void {
    if (!this.isPreview) {
      if (isValue) {
        this.searchTerm = '';
      }
      this.editingStatus = this.findingsData.map(() => false);
      this.filteredFindings = this.findingsData.filter(finding =>
        finding?.finding_text1.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
        finding?.review_finding_type?.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
      this.initializeFormArray();
    }
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.searchFindings();
    }
  }

  saveFinding(finding: ReviewFinding, status: string, formSubmit = false): void {
    const formIndex = this.filteredFindings.indexOf(finding);
    const formGroup = this.findingsFormArray.at(formIndex) as FormGroup;
    const reviewFindingId = finding.id;
    const previousStatus = finding.review_finding_status?.name;
    const previousComment = finding.comment;
    this.selectedStatus = status ? status : previousStatus;
    formGroup.get('comment')?.setValidators([Validators.nullValidator]);
    formGroup.get('comment')?.updateValueAndValidity();
    if (formGroup.valid || status === FindingStatues.APPROVED) {
      const params = {
        status: status,
        comment: formGroup.get('comment')?.value
      }
      if (status === finding?.review_finding_status?.name && !formSubmit) {
        return;
      }
      this.orderService.editFindings(reviewFindingId, params, this.token).subscribe(() => {
        const commentAdded = !!formGroup.get('comment')?.value;
        if (!previousComment) {
          this.findingResolved.emit(commentAdded);
        }
        this.updateFindingsList.emit({ reloadDoc: false, isActionPerformed: true });

        if (status !== FindingStatues.REJECTED) {
          this.sortRevisionArray = this.sortRevisionArray.filter(item => item.id !== finding?.id);
          this.updateSortedList.emit(this.sortRevisionArray);
        }

        if (!this.isDragged) {
          moveItemInArray(this.findingsData, formIndex, formIndex);
          const dropped = {
            previousIndex: formIndex,
            currentIndex: formIndex
          }
          this.moveObject(this.findingsFormArray, formIndex, formIndex);
          this.draggedFinding.emit(dropped);
        }

        if (previousStatus) {
          this.countData[previousStatus]--;
        }
        this.countData[status]++;
      });

      this.editingStatus[formIndex] = false;
    }
  }

  getFindingStatus(index: number, status: string) {
    const findings = this.filteredFindings[index];
    findings.actionStatus = [false, false, false, false];
    findings.actionStatus = [status === FindingStatues.APPROVED || false, status === FindingStatues.REJECTED || false, status === FindingStatues.NOT_APPLICABLE || false, true]
  }

  editFinding(index: number, status: string, tab: string): void {
    this.filteredFindings.forEach((finding, i) => {
      this.editingStatus[i] = false;
    })

    if (this.findingsTabSelection === FindingStatues.RESOLVED && tab === FindingStatues.EDIT) {
      this.filteredFindings[index].actionStatus[3] = true;
    }

    if (this.findingsTabSelection === FindingStatues.RESOLVED && tab === FindingStatues.COMPLETED) {
      this.selectedStatus = status;

      if (this.filteredFindings[index].actionStatus[3] || status === FindingStatues.REJECTED || status === FindingStatues.NOT_APPLICABLE) {
        this.editingStatus[index] = true;
      }
      return;
    }

    if (this.lastIndex !== index || index == 0) {
      this.editingStatus[index] = true;
    } else {
      this.editingStatus[index] = !this.editingStatus[index];
      this.lastIndex = index;
    }
    this.selectedStatus = status;

    const formGroup = this.findingsFormArray.at(index) as FormGroup;
    if (status === FindingStatues.APPROVED) {
      formGroup.get('comment')?.setValidators([Validators.nullValidator]);
      formGroup.get('comment')?.updateValueAndValidity();
      if (this.findingsTabSelection !== FindingStatues.RESOLVED) {
        this.editingStatus[index] = false;
      }
    } else {
      formGroup.get('comment')?.setValidators([Validators.required]);
      formGroup.get('comment')?.updateValueAndValidity();
    }
  }

  cancelFinding(index: number): void {
    const originalValues = this.filteredFindings[index];
    const formGroup = this.findingsFormArray.at(index) as FormGroup;
    formGroup.get('comment')?.setValue(originalValues?.comment);
    this.editingStatus[index] = false;

    const findings = this.filteredFindings[index];
    findings.actionStatus = [false, false, false, false];
    findings.actionStatus = [findings?.review_finding_status?.name === FindingStatues.APPROVED || false, findings?.review_finding_status?.name === FindingStatues.REJECTED || false, findings?.review_finding_status?.name === FindingStatues.NOT_APPLICABLE || false, false]
  }

  removeSpaces(index: number): void {
    const formGroup = this.findingsFormArray.at(index) as FormGroup;
    const control = formGroup.get('comment');
    if (control && control.value) {
      const newValue = control.value.trim();
      control.setValue(newValue);
    }
  }

  openDeleteFindingConfirmationModal(finding: ReviewFinding) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      panelClass: ['order-complete-modal'],
      maxWidth: '100%',
      disableClose: true,
      data: {
        heading: 'Delete Finding',
        contentData: message.DeleteFinding,
        findingId: finding.id
      }
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if(result) {
        const formIndex = this.filteredFindings.indexOf(finding);
        this.editingStatus[formIndex] = false;
        this.updateFindingsList.emit({ reloadDoc: false, isActionPerformed: true });
      }
    });
  }

  onAllCheckboxChange(event: MatCheckboxChange) {
    this.toggleManualFinding = false;
    if (event?.checked) {
      this.selectedCheckedIds = [];
      this.filteredFindings.forEach((element: any) => {
        this.selectedCheckedIds.push(element.id)
      });
      this.isAnyCheckboxSelected = true;
    } else {
      this.selectedCheckedIds = [];
      this.isAnyCheckboxSelected = false;
      this.isBulkStatusChanges = false;
    }
  }

  onCheckboxChange(event: MatCheckboxChange, row: any) {
    if (event?.checked) {
      this.selectedCheckedIds.push(row.id);
    } else {
      for (let i = 0; i < this.selectedCheckedIds.length; i++) {
        if (this.selectedCheckedIds[i] === row.id) {
          this.selectedCheckedIds.splice(i, 1);
          break;
        }
      }
    }
    this.filteredFindings.forEach((element: any) => {
      element.selected = this.selectedCheckedIds.includes(element.id);
    });
    this.isAnyCheckboxSelected = this.selection.selected.length > 0;
    this.toggleManualFinding = false;
    if(!this.selectedCheckedIds.length) {
      this.isBulkStatusChanges = false;
    }
  }

  applyBulkAction(status: string):void {
    this.addBulkActionStatus = status;
    if (this.addBulkActionStatus !== 'Approved') {
      this.addBulkActionComment.setValidators([Validators.required]);
    } else {
      this.addBulkActionComment.clearValidators();
    }
    this.addBulkActionComment.updateValueAndValidity();
  }

  resolveFindingsInBulk(): void {
    const payload = {
      review_finding_data: {
        status: this.addBulkActionStatus,
        comment: this.addBulkActionComment.value
      },
      review_finding_ids: this.selectedCheckedIds
    };
    this.orderService.updateFindingsInBulk(payload)
    .then(
      () => {
        this.clearCheckboxSelections();
        this.isBulkStatusChanges = false;
        this.updateFindingsList.emit({ reloadDoc: false, isActionPerformed: true });
      },
      (err) => {
        this.toastr.error(err.message);
      },
    );
  }

  cancelBulkAction(): void {
    this.addBulkActionComment.reset('');
    this.addBulkActionStatus = '';
    this.isBulkStatusChanges = false;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.filteredFindings && this.filteredFindings?.length > 0 ? this.filteredFindings?.length : 0;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.filteredFindings);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.orderno + 1}`;
  }

  clearCheckboxSelections(): void {
    this.selection.clear();
    this.isAnyCheckboxSelected = false;
    this.selectedCheckedIds = [];
  }

  changeStatusInBulk(): void {
    this.isBulkStatusChanges = true;
  }

  closeFindingBox(isAdded: boolean) {
    this.toggleManualFinding = false;
    if(isAdded) {
      this.updateFindingsList.emit({ reloadDoc: false, isActionPerformed: true });
    }
  }

  toggleAddFindingBox() {
    this.toggleManualFinding = !this.toggleManualFinding;
    if(this.isFilterExpanded) {
      this.isFilterExpanded = false;
      this.selectedStatuses = [];
      this.selectedTypes = [];
      this.isFilterApplied = false;
    }
    this.toggleSearch = false;
  }

  toggleFilter(): void {
    this.isFilterExpanded = !this.isFilterExpanded;
    this.toggleManualFinding = false;
    this.toggleSearch = false;
    this.selectedStatuses = [...this.previousFilterStatus];
    this.selectedTypes = [...this.previousFilterTypes];
  }

  toggleSearchBar(): void {
    this.toggleSearch = !this.toggleSearch;
    this.toggleManualFinding = false;
    this.isFilterExpanded = false;
  }

  selectStatusFilter(status: string) {
    const index = this.selectedStatuses.indexOf(status);
    if (index === -1) {
      this.selectedStatuses.push(status);
    } else {
      this.selectedStatuses.splice(index, 1);
    }
  }

  selectTypeFilter(type: string) {
    const index = this.selectedTypes.indexOf(type);
    if (index === -1) {
      this.selectedTypes.push(type);
    } else {
      this.selectedTypes.splice(index, 1);
    }
  }

  removeFilters(isStatusFilter: boolean): void {
    isStatusFilter ? this.selectedStatuses = [] : this.selectedTypes = [];
    this.previousFilterStatus = [...this.selectedStatuses];
    this.previousFilterTypes = [...this.selectedTypes];
    if(!this.selectedStatuses.length && !this.selectedTypes.length) {
      this.isFilterApplied = false;
      this.updateFindingsList.emit({ reloadDoc: false, isActionPerformed: false });
    } else {
      this.applyFilter();
    }
  }

  applyFilter(): void {
    this.filterFindings = [];
    this.previousFilterStatus = [...this.selectedStatuses];
    this.previousFilterTypes = [...this.selectedTypes];
    if(this.selectedTypes.length && this.selectedStatuses.length) {
      for(const type of this.selectedTypes) {
        for(const status of this.selectedStatuses) {
          const combinedFilter = this.originalFindingsData.filter(finding =>
            finding?.review_finding_type?.name.toLowerCase().includes(type.toLowerCase()) &&
            finding?.review_finding_status?.name.toLowerCase().includes(status.toLowerCase())
          );
          this.filterFindings = [...this.filterFindings, ...combinedFilter];
        }
      }
      this.isFilterApplied = true;
    } else if(this.selectedTypes.length){
      for (const type of this.selectedTypes) {
        const filteredRecords = this.originalFindingsData.filter(finding =>
          finding?.review_finding_type?.name.toLowerCase().includes(type.toLowerCase())
        );
        this.filterFindings = [...this.filterFindings, ...filteredRecords];
      }
      this.isFilterApplied = true;
    } else if(this.selectedStatuses.length){
      for (const status of this.selectedStatuses) {
        const filteredRecords = this.originalFindingsData.filter(finding =>
          finding?.review_finding_status?.name.toLowerCase().includes(status.toLowerCase())
        );
        this.filterFindings = [...this.filterFindings, ...filteredRecords];
      }
      this.isFilterApplied = true;
    } else {
      this.filterFindings = this.originalFindingsData;
      this.isFilterApplied = false;
    }
    this.applyFilterOnFindings.emit(this.filterFindings);
    this.isFilterExpanded = false;
  }

  drop(event: any) {
    moveItemInArray(this.findingsData, event.previousIndex, event.currentIndex);
    const dropped = {
      previousIndex: event.previousIndex,
      currentIndex: event.currentIndex
    }
    this.moveObject(this.findingsFormArray, event.previousIndex, event.currentIndex);
    this.draggedFinding.emit(dropped);
    this.isDragged = true;
  }

  moveObject(array: any, previousIndex: number, currentIndex: number) {
    if (currentIndex >= array.length) {
        let k = currentIndex - array.length;
        while ((k--) + 1) {
            array.push(undefined);
        }
    }
    array.splice(currentIndex, 0, array.splice(previousIndex, 1)[0]);
    return array;
  }

}
