import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class EventEmitterService {
  pageTitleOnHeaderEvent: Subject<string> = new Subject();
  notificatoinCount = new BehaviorSubject<number>(0);
  data = this.notificatoinCount.asObservable();
  tableActionsEvent: Subject<string> = new Subject();
  refreshTableEvent: Subject<string> = new Subject();

  emitPageTitleOnHeader(value: string): void {
    this.pageTitleOnHeaderEvent.next(value);
  }

  updateNotificationCount(count: number) {
    this.notificatoinCount.next(count);
  }

  emitTableActionsOnClick(value: string): void {
    this.tableActionsEvent.next(value);
  }

  emitRefreshTable(value: string): void {
    this.refreshTableEvent.next(value);
  }
}
