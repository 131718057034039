import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { ErrorHandlerService } from './services/error-handler.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// Firebase modules
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';

import { RequestInterceptor } from './interceptors/request-interceptor';
import { CachingInterceptor } from './interceptors/caching-interceptor';

import { AuthService } from '../auth/auth.service';
import { ToastService } from './services/toast.service';
import { ApiCacheService } from './services/api-cache.service';
import { environment } from 'src/environments/environment';
import { EventEmitterService } from './services/event-emitter.service';
import { NgxMaskModule, IConfig } from 'ngx-mask';

const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CachingInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    AuthService,
    ToastService,
    ApiCacheService,
    EventEmitterService
  ],
  declarations: [],
  imports: [
    CommonModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    NgxMaskModule.forRoot(maskConfig)
  ],
  exports: [LoadingBarHttpClientModule],
})
export class CoreModule {}
