export const message = {
  orderNoLongerAvailable: 'Order review no longer available',
  columnsLimit: 'There must be atlease three columns selected',
  DeleteMessageConfirmation: 'Are you sure, you want to delete the message?',
  archivedOrder: 'Order has been archived.',
  alreadyArchivedOrder: 'Order has already been archived.',
  archiveOrderConfirmation: 'Are you sure, you want to archive this order?',
  revisionRequested: 'Request for Revision has been sent successfully.',
  alreadyRequestedRevision: 'Revision has already been requested.',
  requestForRevisionConfirmation: 'Your Request for Revision has been sent successfully.',
  markedAsCompletedReview: 'Review has been marked as completed.',
  alreadymarkedAsCompletedReview: 'Order has already marked as completed.',
  orderCompletionConfirmation: 'Your file has been successfully submitted.',
  assignToMyself: 'Review has been assigned to you!',
  uploadDocument: 'Document has been uploaded',
  updateReviewerAssignment: 'Reviewer has been updated',
  disbaleEditing: 'You cannot edit this order as order has already marked as completed.',
  NoResultsFound: 'No Results found',
  startConversation: 'Start a Conversation',
  ruleCreated: 'created successfully',
  ruleUpdated: 'update successfully',
  ruleDeleted: 'deleted successfully',
  activated: 'has been activated.',
  deactivated: 'has been deactivated.',
  emailSent: 'Email has been sent.',
  disabledUser: 'Please connect with administrator to activate your account.',
  resetPassword: 'Password has been reset successfully.',
  markUserMessage: 'Are you sure, you want to mark user as',
  NoReviewIdAvailable: 'You cannot edit this order as there is no review available against it.',
  addressVerified: 'Address verified against USPS.',
  addressNotVerified: 'Address unable to be verified against USPS.',
  sameStatusError: 'Order has already been in ',
  orderReviewSubmitted: 'Order has been submitted for review.',
  accountAlreadyLinked: 'Microsoft account is already linked.',
  accountLinked: 'Microsoft account linked successfully.',
  restrictEyeIconDisplayOnPDF: 'You would not be able to annotate to the findings in PDF view.',
  DeleteFinding: 'Are you sure, you want to delete this finding?',
  DeleteManualFindingGroup: 'Are you sure, you want to delete this manual finding group code?',
  DeleteMfgSuccessMessage: 'Manual Finding Group has been deleted.',
  GetNextText: 'Order previously assigned through Get Next has not been completed yet, please complete the order first to receive the next available review.',
  saveConfigurations: 'Configurations saved successfully.',
  updateConfigurations: 'Configurations updated successfully.',
  submissionToEAD: 'Request has been sent for the submission to EAD.',
  submissionToUCDP: 'Request has been sent for the submission to UCDP.',
  overrideRequest: 'Override submission request has been sent.',
  outOffOffice: 'You are designated as out of office and cannot be assigned orders. To update, kindly get in touch with the admin.',
  offTiming: 'Order cannot be assigned during non-shift hours.',
  layoutPreference: 'Layout preference updated.',
  startTimeIndicator: 'End time is smaller than the start time indicating shift ends in a different date',
  deleteEadAppraisalMessage: 'Your request to delete the EAD appraisal has been sent.',
  deleteUcdpAppraisalMessage: 'Your request to delete the UCDP appraisal has been sent.',
  deleteEADappraisalContent: `Are you sure you want to delete the <strong>"EAD Appraisal"</strong>? Deleting would cause the appraisal to be removed from the EAD portal permanently.`,
  deleteUCDPappraisalContent: `Are you sure you want to delete the <strong>"UCDP Appraisal"</strong>? Deleting would cause the appraisal to be removed from the UCDP portal permanently.`,
  roleCreated: 'Role has been created',
  roleUpdated: 'Role has been updated',
  roleStatus: 'Role status updated successfully',
  roleDeleted: 'Role deleted successfully',
  roleDeletedConfirmation: 'Are you sure you want to delete this role',
  basicNSRule: `Select fields to highlight on the form and specify the message you wish to display when the rule activates. Selected fields will be shown in the finding message in {}, multiple fields can be selected.,
  refreshRule: 'Some items in the order list are updated. Click to refresh the table.',
  For Example:
  "Type FindLender/Client: {} The appraiser did not label the Lender/Client as "Abc Company".`,
  refreshRule: 'Some items in the order list are updated. Click to refresh the table.',
}
