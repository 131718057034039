/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';

import { deepClone } from 'src/app/shared/helpers';

@Injectable()
export class ApiCacheService {
  cache: any = new Map<string, HttpResponse<any>>();

  // constructor() {}

  get(req: HttpRequest<any>): HttpResponse<any> | undefined {
    const url: string = req.urlWithParams;
    const response: HttpResponse<any> = this.cache.get(url);
    if (response) {
      return response.clone({
        body: deepClone(response.body),
      });
    }
    return response;
  }

  put(req: HttpRequest<any>, response: HttpResponse<any>): void {
    const url: string = req.urlWithParams;
    const responseClone: HttpResponse<any> = response.clone({
      body: deepClone(response.body),
    });
    this.cache.set(url, responseClone);
  }

  remove(url: string): void {
    this.cache.delete(url);
  }

}
