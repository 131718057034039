import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Modules
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ErrorModule } from './error/error.module';
import { SharedModule } from './shared/shared.module';

// Guards
import { AuthGuard } from './core/guards/auth.guard';
import { PublicGuard } from './core/guards/public.guard';
import { ToastrModule } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { LocalDatePipe } from './shared/pipes/local-date.pipe';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';

const config: SocketIoConfig = {
  url: `${environment.baseURL}${environment.socketNamespace}`,
  options: {transports:['websocket']}
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CoreModule,
    ErrorModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    [SocketIoModule.forRoot(config)],
    SharedModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right'
    }),
  ],
  providers: [
    AuthGuard,
    PublicGuard,
    LocalDatePipe,
    DatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
