import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { config } from '../constants';
import { NgClass } from '@angular/common';

@Directive({
  selector: '[smartTruncate]',
})
export class SmartTruncateDirective implements OnInit, AfterViewInit {
  maxNotesViewableLength: number = config.maxNotesViewableLength;
  isTruncated = true;

  @Input() description: string;
  @Output() OnViewMore: EventEmitter<boolean> = new EventEmitter();

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    const truncatedText = this.addEllipsis(this.description, this.maxNotesViewableLength);
    this.elementRef.nativeElement.innerHTML = truncatedText;
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement
      ?.querySelector('button')
      ?.addEventListener('click', this.onViewMoreDesc.bind(this));
  }

  addEllipsis(text: string, limit: number){
    if (text.length <= limit) {
        return text;
    } else {
        let truncatedText = text.substring(0, limit);

        const viewMoreButton = `<span><button class="read-more-btn" type="button">Read More</button></span>`;

        // Check if the limit ends in the middle of a tag
        const tagRegex = /<[^>]*$/;
        const tagMatch = truncatedText.match(tagRegex);
        if (tagMatch !== null && tagMatch !== undefined) {
            // If the limit ends in the middle of a tag, move backward to find the nearest opening tag
            const openingTagRegex = /<[^>]*>/g;
            const openingTagMatches = [...text.matchAll(openingTagRegex)].reverse(); // Reverse the array

            // Check if there are any opening tags found
            if (openingTagMatches.length > 0) {
                const nearestOpeningTag = openingTagMatches.find((match: any) => match.index < limit);

                if (nearestOpeningTag) {
                    const tagIndex = nearestOpeningTag.index;
                    truncatedText = text.substring(0, tagIndex);
                }
            }
        }

        // Ensure truncated text ends with a properly closed tag
        const closingTagRegex = /<\/\w+>$/;
        const closingTagMatch = truncatedText.match(closingTagRegex);
        if (!closingTagMatch) {
            // Find the last opening tag in the truncated text
            const lastOpeningTagIndex = truncatedText.lastIndexOf('<');
            truncatedText = truncatedText.substring(0, lastOpeningTagIndex);
        }

        return truncatedText.trim() + '...' + viewMoreButton;
    }
  }


  onViewMoreDesc() {
    this.isTruncated = !this.isTruncated;
    this.toggleText();
    this.OnViewMore.emit(this.isTruncated);
  }

  toggleText() {
    if (this.isTruncated) {
      const truncatedText = this.addEllipsis(this.description, this.maxNotesViewableLength);
      this.elementRef.nativeElement.innerHTML = truncatedText;
    } else {
      this.elementRef.nativeElement.innerHTML = this.description;
    }
  }
}
