import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getAuth, linkWithPopup } from 'firebase/auth';
import { AuthService } from 'src/app/auth/auth.service';
import { permission, ROUTE } from 'src/app/shared/constants';
import { UserRole } from 'src/app/shared/enums/user';
import { ToastService } from './toast.service';
import { message } from 'src/app/shared/constants/alerts_messages';
import { authErrorMessages } from 'src/app/shared/errors/error-messages';
import { replaceNonASCIICharacters } from 'src/app/shared/helpers/common';
import { UserLayoutPreferencePayload } from 'src/app/shared/interfaces/user';

@Injectable({
  providedIn: 'root',
})
export class UserConfigurationService {
  param: any;
  authErrors = authErrorMessages;
  constructor(private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private toastr: ToastService) {
    this.route.queryParams
      ?.subscribe((params:any) => {
        this.param = params.q;
      }
    );
  }

  getUserConfigurations(uid: any): Promise<any> {
    return this.http.get(`users/config?external_id=${uid}`).toPromise();
  }

  updateLayoutPreference(uid: string, data: UserLayoutPreferencePayload): Promise<any> {
    return this.http.put(`users/layout_preference/${uid}`, data).toPromise();
  }

  getLandingPage() {
    const encodedConfigurations: any = localStorage.getItem('configurations');
    const permissionToRouteMap = this.getPermissionToRouteMap();

    if (!encodedConfigurations) {
        this.navigateToError();
        return false;
    }

    const decodedConfigurations = JSON.parse(atob(encodedConfigurations));
    const sortedPermissions = this.sortPermissions(decodedConfigurations.permissions);
    const matchingPermission = sortedPermissions.find(permission => permissionToRouteMap.hasOwnProperty(permission));

    if (matchingPermission) {
        this.handleMatchingPermission(matchingPermission, permissionToRouteMap);
        return true;
    }

    this.navigateToError();
    return false;
  }

  getPermissionToRouteMap() {
    return {
        [permission.dashboard[0]]: ROUTE.DASHBOARD,
        [permission.analytics[0]]: ROUTE.ANALYTICS,
        [permission.performance[0]]: ROUTE.PERFORMANCE,
        [permission.orders[0]]: ROUTE.ORDERS,
        [permission.rules[0]]: ROUTE.RULES,
        [permission.clientProfiles[0]]: ROUTE.CLIENTPROFILE,
        [permission.users[0]]: ROUTE.USERS,
        [permission.messages[0]]: ROUTE.MESSAGES,
        [permission.settings[0]]: ROUTE.SETTINGS,
    };
  }

  navigateToError() {
    this.router.navigate(['/error']);
  }

  sortPermissions(permissions: string[]) {
    const viewPermissionsOrder = [
        permission.dashboard[0],
        permission.analytics[0],
        permission.performance[0],
        permission.orders[0],
        permission.rules[0],
        permission.clientProfiles[0],
        permission.users[0],
        permission.messages[0],
        permission.settings[0],
    ];

    return permissions.sort((a: string, b: string) => {
        return viewPermissionsOrder.indexOf(a) - viewPermissionsOrder.indexOf(b);
    });
  }

  handleMatchingPermission(matchingPermission: string, permissionToRouteMap: any) {
    this.router.navigate([permissionToRouteMap[matchingPermission]]);
    if (this.param === 'link-account') {
        this.linkAccount();
    }
  }

  linkAccount() {
    const provider = this.authService.getMicrosoftProvider();
    const auth = getAuth();
    if (auth?.currentUser) {
        const linkedProviders = auth.currentUser.providerData.map(provider => provider.providerId);
        if (linkedProviders.includes('microsoft.com')) {
            this.toastr.info(message.accountAlreadyLinked);
        } else {
            linkWithPopup(auth.currentUser, provider).then(() => {
                this.toastr.success(message.accountLinked);
            }).catch((error) => {
                const errorMessage = this.authErrors[error.code];
                this.toastr.error(errorMessage ? errorMessage : error.message);
            });
        }
    }
  }


  checkPermissions(permissions: string): boolean {
    const encodedConfigurations: any = localStorage.getItem('configurations');
    if (encodedConfigurations) {
      const configurations = JSON.parse(atob(encodedConfigurations));
      return configurations.permissions ? configurations.permissions.includes(permissions) : false;
    }
    return false;
  }

  checkUserRole(roleToCheck = UserRole.REVIEWER) {
    const encodedConfigurations = localStorage.getItem('configurations');

    if (encodedConfigurations !== null) {
      const decodedConfigurations = JSON.parse(atob(encodedConfigurations));

      if (decodedConfigurations && decodedConfigurations.role) {
        if (roleToCheck === UserRole.REVIEWER) {
          return decodedConfigurations.role.includes(UserRole.REVIEWER);
        } else if (roleToCheck === UserRole.ADMIN) {
          return decodedConfigurations.role.includes(UserRole.ADMIN);
        } else if (roleToCheck === UserRole.MANAGER) {
          return decodedConfigurations.role.includes(UserRole.MANAGER);
        }
      }
    }

    return false;
  }

  getUserConfigurationsFromLS () {
    const encodedConfigurations: any = localStorage.getItem('configurations');
    if (encodedConfigurations !== null) {
      const decodedConfigurations: any = JSON.parse(atob(encodedConfigurations));
      return decodedConfigurations;
    }
  }

  updateUserConfigurations(configurations: any) {
    localStorage.setItem('configurations', btoa(replaceNonASCIICharacters(JSON.stringify(configurations))));
  }

}
