import { permission } from './permissions';
import { ROUTE } from './routes';

// eslint-disable-next-line
export const menu = [
  {
    label: 'Dashboard',
    icon: 'home-sidebar.svg',
    navigate: ROUTE.DASHBOARD,
    hasAccess: false,
    permission: permission.dashboard,
  },
  {
    label: 'Analytics',
    icon: 'text-box-multiple-outline.svg',
    navigate: ROUTE.ANALYTICS,
    hasAccess: false,
    permission: permission.analytics,
  },
  {
    label: 'Orders',
    icon: 'text-box-multiple-outline.svg',
    navigate: ROUTE.ORDERS,
    hasAccess: false,
    permission: permission.orders,
  },
  {
    label: 'Rules',
    icon: 'arrow-decision-outline.svg',
    navigate: ROUTE.RULES,
    hasAccess: false,
    permission: permission.rules,
  },
  {
    label: 'Client Profile',
    icon: 'account-box-outline.svg',
    navigate: ROUTE.CLIENTPROFILE,
    hasAccess: false,
    permission: permission.clientProfiles,
  },
  {
    label: 'Users',
    icon: 'account-group-outline.svg',
    navigate: ROUTE.USERS,
    hasAccess: false,
    permission: permission.users,
  },
  {
    label: 'Computer Vision',
    icon: 'text-box-multiple-outline.svg',
    navigate: ROUTE.COMPUTERVISION,
    hasAccess: false,
    permission: permission.computerVision,
  },
  {
    label: 'OCR',
    icon: 'text-box-multiple-outline.svg',
    navigate: ROUTE.OCR,
    hasAccess: false,
    permission: permission.ocr,
  },
  {
    label: 'Role Management',
    icon: 'role.svg',
    navigate: ROUTE.ROLEMANAGEMENT,
    hasAccess: false,
    permission: permission.roleManagement,
  }
];
