import { Component, Inject, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { OrderService } from '../../order.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { startWith, map } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { message } from 'src/app/shared/constants/alerts_messages';
import { UserConfigurationService } from 'src/app/core/services/user-configuration.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalTitle } from 'src/app/shared/enums';
import { ActionPermissions } from 'src/app/shared/constants/permissions';

@Component({
  selector: 'app-order-reviewer-assignment',
  templateUrl: './order-reviewer-assignment.component.html',
  styleUrls: ['./order-reviewer-assignment.component.scss']
})
export class OrderReviewerAssignmentComponent {
  @ViewChild(MatAutocompleteTrigger) auto!: MatAutocompleteTrigger;
  reviewerList: any = [];
  filteredReviewers: any[] = [];
  reviewerCtrl = new FormControl();
  title: string;
  permission = ActionPermissions;

  constructor(
    public dialogRef: MatDialogRef<OrderReviewerAssignmentComponent>,
    private orderService: OrderService,
    private userConfig: UserConfigurationService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService, private route: ActivatedRoute
  ) {
    this.title = data.title;
  }

  ngOnInit(): void {
    this.getReviewers();
  }

  getReviewers(): void {
    let params = '';
    if (this.userConfig.checkPermissions(this.permission.ESCALATE_TO) && this.title === ModalTitle.ESCALATE_TO) {
      params = '?manager_check=true';
    }
    this.orderService.getReviewers(params)?.then((reviewers) => {
      this.reviewerList = reviewers;
      this.filteredReviewers = this.reviewerList;
      this.setupFilter();
    });
  }

  setupFilter(): void {
    this.reviewerCtrl.valueChanges
      .pipe(startWith(''), map((value) => this.filterReviewers(value)))
      .subscribe((filteredReviewers) => {
        this.filteredReviewers = filteredReviewers;
      });
  }

  filterReviewers(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.reviewerList
      .filter(
        (reviewer: any) =>
          reviewer.user.first_name.toLowerCase().includes(filterValue) ||
          reviewer.user.last_name?.toLowerCase().includes(filterValue)
      )
      .map((reviewer: any) => `${reviewer.user.first_name} ${reviewer.user.last_name ? reviewer.user.last_name : ''}`);
  }

  onReviewerSelected(event: any): void {
    const selectedReviewerName = event.option.value;
    this.reviewerCtrl.setValue(selectedReviewerName);
  }

  updateReviewer(id: string): void {
    const requestBody = {
      order_number: this.data.orderNumber,
      external_id: id
    };
    this.orderService.updateReviewer(requestBody)?.subscribe(
      (response) => {
        if (response) {
          this.toastr.success(message.updateReviewerAssignment);
          this.dialogRef.close(response);
          const queryParams = { ...this.route.snapshot.queryParams, isPreview: true };
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: queryParams,
            queryParamsHandling: 'merge'
          });
        }
      },
      (error) => {
        this.toastr.error(error.error.detail);
      }
    );
  }

  updateOrdersReviewerInBulk(id: string): void {
    const requestBody = {
      orderIds: this.data.orderIds,
      reviewerId: id
    };
    this.orderService.updateReviewerInBulk(requestBody)?.subscribe(
      (response) => {
        if (response) {
          this.toastr.success(message.updateReviewerAssignment);
          this.dialogRef.close(response);
        }
      },
      (error) => {
        this.toastr.error(error.error.detail);
      }
    );
  }

  saveReviewAssignment(): void {
    const selectedReviewerName = this.reviewerCtrl.value;
    const selectedReviewer = this.reviewerList.find(
      (reviewer: any) =>
        `${reviewer.user.first_name} ${reviewer.user.last_name ? reviewer.user.last_name : ''}` === selectedReviewerName
    );
    const selectedReviewerId = selectedReviewer ? selectedReviewer.user.external_id : '';

    this.data.isBulkAssignment ? this.updateOrdersReviewerInBulk(selectedReviewerId) : this.updateReviewer(selectedReviewerId);
  }

  showReviewerError(): boolean {
    const selectedReviewerName = this.reviewerCtrl.value;
    return (
      selectedReviewerName &&
      !this.reviewerList.some(
        (reviewer: any) =>
          `${reviewer.user.first_name} ${reviewer.user.last_name ? reviewer.user.last_name : ''}` === selectedReviewerName
      )
    );
  }

}
