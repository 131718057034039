import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { AppRoutingModule, routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, RouterModule } from '@angular/router';
import { CoreModule } from './core/core.module';
import { DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AuthGuard } from './core/guards/auth.guard';
import { PublicGuard } from './core/guards/public.guard';
import { ErrorModule } from './error/error.module';
import { LocalDatePipe } from './shared/pipes/local-date.pipe';
import { SharedModule } from './shared/shared.module';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimationsAsync(),
    importProvidersFrom(
      BrowserModule,
      CoreModule,
      ErrorModule,
      AppRoutingModule,
      RouterModule,
      BrowserAnimationsModule,
      SharedModule,
      ToastrModule.forRoot({
        positionClass: 'toast-top-right'
      }),
      LoadingBarHttpClientModule
    ),
    // Global service providers
    AuthGuard,
    PublicGuard,
    LocalDatePipe,
    DatePipe
  ]
};
